











import type { PropType } from 'vue';

import { SuperAdminOperatorMenuItem } from '@/api/schema';
const SuperAdminOperatorMenuLink = (): Promise<any> =>
  import(
    '@/views/SuperAdminViews/super-admin-operator-view-menu/SuperAdminOperatorMenuLink.vue'
  );
const SuperAdminOperatorMenuGroup = (): Promise<any> =>
  import(
    '@/views/SuperAdminViews/super-admin-operator-view-menu/SuperAdminOperatorMenuGroup.vue'
  );
export default {
  name: 'SuperAdminOperatorViewMenu',
  components: {
    SuperAdminOperatorMenuLink,
    SuperAdminOperatorMenuGroup
  },
  props: {
    menu: {
      type: Array as PropType<SuperAdminOperatorMenuItem[]>,
      default: (): [] => [],
      required: true
    }
  },
  methods: {
    resolveNavHeaderComponent(item: SuperAdminOperatorMenuItem): string {
      if (item.children) return 'super-admin-operator-menu-group';

      return 'super-admin-operator-menu-link';
    }
  }
};
